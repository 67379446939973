import React, { useEffect } from 'react';
import { Carousel, Col, Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Hamburger from '../Menu';
import { getCookie } from '../../common/utils';
import Newheader from '../headerNew';

function LoggedInAbout() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!getCookie('accessToken')) {
      navigate('/', { replace: true });
    }
  }, []);

  const cardContent = [
    'I am Vivekanand, is a unique web-based platform that harnesses the advanced capabilities of Partex AI to bring history to life', "By capturing the unique texture and tone of your voice, this platform recreates Swami Vivekanand's legendary speech, making it sound as if it were spoken by you. Step into the past, connect with the wisdom of Swami Vivekanand, and let your voice echo the timeless words of enlightenment."];

  return (
    <div className="landing-page">
      <Newheader />
      <Hamburger isOpen={false} className="hamberger-icon" />
      <div className="new-landing-page1 new-landing-page">
        <Row className="row row-common">
          <Col span={10} className="section-1">
            <div className="logos">
              <img className="lullabai-logo" src="/images/logo-ai.png" alt="Vivekanand Logo" />
              <img className="lullabai-mobile-logo" src="/images/mobile-logo-ai.png" alt="Vivekanand Logo" />
              <img className="alembic-icon" src="/images/alembic-icon.svg" alt="Alembic Icon" />
            </div>
          </Col>
          {/* <Col span={4} className="middle-section" /> */}

          <Col span={10} className="section-2 section-with-carousel">
            <div className="card-content">
              <Carousel
                arrows
                prevArrow={<LeftOutlined className="left-arrow" />}
                nextArrow={<RightOutlined className="right-arrow" />}
                dots={false}
              // infinite={false}
              >
                {cardContent.map((content) => (
                  <div className="about">
                    <div className="desc">{content}</div>
                  </div>
                ))}
              </Carousel>
              <button
                className="skip"
                type="submit"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Skip
              </button>
            </div>
            <div className="powerdby-sec">
              <div className="powerdby-img" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default LoggedInAbout;
