/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
import { Carousel, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getProcessedSongListAction } from '../SelectSong/logic';
import { getCookie } from '../../common/utils';
import Newheader from '../headerNew';

function Instructions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signUpData = useSelector((state) => state.postSignUpData);
  const [showPlayButton, setShowPlayButton] = useState(true);

  useEffect(() => {
    if (!getCookie('accessToken')) {
      navigate('/', { replace: true });
    }

    setTimeout(() => {
      setShowPlayButton(false);
    }, 2000);
  }, []);
  useEffect(() => {
    if (signUpData.flag) {
      toast.success('Signup Successful', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      navigate('/');
    }
    if (signUpData.error) {
      navigate('/');
      toast.error('Email Already Registered', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [signUpData]);

  return (
    <>
      <Newheader />
      <div className="instruction-page">
        <Row className="row row-common">
          <Col span={12} className="section-1">
            <div className="logos">
              <img className="lullabai-logo" src="/images/logo-ai.png" />
              <img className="alembic-icon" src="/images/alembic-icon.svg" />
            </div>
          </Col>
          <Col span={12} className="section-2">
            <div className="heading-text">How to record your speech?</div>

            <div className="instruction-container-wrap">
              <div className="instrictions-outer-container">
                <div className="instrictions-outer-container-list">
                  {/* <video src="https://lullabai.forherwithher.in/video/VID-20240722-WA0000.mp4" controls>
                      <source
                        src="https://lullabai.forherwithher.in/video/VID-20240722-WA0000.mp4"
                        type="video/mp4"
                      />
                    </video> */}
                  <ul>
                    <li>Find a quiet place.</li>
                    <li>Choose one speech to process in your voice</li>
                    <li>hold microphone close to your mouth</li>
                    <li>Control your breathing.</li>
                    <li>Tap to record and read letters aloud for 30 sec</li>
                    <li>Wait while your speech being prepared</li>
                    <li>Play the Swami Vivekanand speech in your voice voice</li>
                  </ul>
                </div>
                <button
                  type="button"
                  className="next"
                  onClick={() => {
                    navigate('/select-song'); dispatch(getProcessedSongListAction({}));
                  }}
                >
                  Next
                </button>
              </div>
            </div>
            <div className="powerdby-sec">
              <div className="powerdby-img" />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Instructions;
