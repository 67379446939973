import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, Collapse, Row } from 'antd';
// import { DownOutlined } from '@ant-design/icons';
import Hamburger from '../Menu';
import { getCookie } from '../../common/utils';
import Newheader from '../headerNew';

function FAQ() {
  const navigate = useNavigate();
  const signUpData = useSelector((state) => state.postSignUpData);

  useEffect(() => {
    if (!getCookie('accessToken')) {
      navigate('/', { replace: true });
    }
  }, []);

  // Handle signup success or error
  useEffect(() => {
    if (signUpData.flag) {
      toast.success('Signup Successful', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      navigate('/');
    }
    if (signUpData.error) {
      navigate('/');
      toast.error('Email Already Registered', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [signUpData, navigate]);

  const items = [
    {
      key: '1',
      label: 'How do I start recording a voice?',
      children: (
        <p>
          To start recording, complete the signup process, follow the
          instructions and you will be redirected to the recording page, give
          the mic permission and click on the Mic icon.
        </p>
      ),
    },
    {
      key: '2',
      label: 'Can I download my recorded speech?',
      children: (
        <p>No. Currently we only support online listening of the speech, and you can share it on facebook, twitter and whatsapp.</p>
      ),
    },
    {
      key: '3',
      label: 'How is my data protected?',
      children: (
        <p>
          We take data privacy and security seriously. All user data is
          encrypted and stored securely in compliance with industry standards.
        </p>
      ),
    },
    {
      key: '4',
      label: 'How seamless is the voice integration in the processed speech?',
      children: (
        <p>
          Our algorithms are constantly learning and improving, aiming to
          provide highly accurate recommendations based on your feedback and
          listening patterns.
        </p>
      ),
    },
    {
      key: '5',
      label: 'Can I integrate the application with other music software?',
      children: (
        <p>No. Currently we do not have support for third party softwares.</p>
      ),
    },
  ];

  return (
    <div className="landing-page">
      <Newheader />
      <Hamburger isOpen={false} className="hamberger-icon" />
      <div className="faq-page new-landing-page">
        <Row className="row row-common">
          <Col span={10} className="section-1">
            <div className="logos">
              <img
                className="lullabai-logo"
                src="/images/logo-ai.png"
                alt="Swami Vivekanand Logo"
              />
              {/* <img
                className="alembic-icon"
                src="/images/alembic-icon.svg"
                alt="Alembic Icon"
              /> */}
            </div>
          </Col>

          <Col span={10} className="section-2">
            <div className="heading-text">Frequently Asked Questions</div>
            <div className="select-song-container-wrap">
              <div className="select-song-list">
                <Collapse className="faq-collapse" accordion items={items} />
              </div>
              <button
                className="next"
                type="button"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </button>
            </div>
            <div className="powerdby-sec">
              <div className="powerdby-img" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default FAQ;
