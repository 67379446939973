import React from 'react';

function Newheader() {
  return (
    <div className="header">
      <div className="left-content">
        <div className="inno-logo" />
      </div>
      <div className="right-content">
        <div className="jf-logo" />
        <div className="echoes-logo" />
      </div>
    </div>
  );
}
export default Newheader;
