/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
// import { useNavigate } from 'react-router-dom';
// import Auth0Lock from 'auth0-lock';
import auth0 from 'auth0-js';
import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { getCookie, removeCookies } from '../../common/utils';

function Hamburger({ isOpen = false }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(isOpen);
  // const voiceRecordData = useSelector((state) => state?.getRecordingList);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const webAuth = new auth0.WebAuth({
    domain: process.env.auth0.domain,
    clientID: process.env.auth0.clientId,
    audience: process.env.auth0.audience,
    response_type: 'token id_token',
  });
  // const lock = new Auth0Lock(process.env.auth0.clientId, process.env.auth0.domain, {
  //   container: '',
  //   autoclose: true,
  //   theme: {
  //     logo: 'https://lullabai.forherwithher.in/images/logo-ai.png',
  //   },
  // });

  const handleLogout = () => {
    removeCookies('accessToken');
    removeCookies('permissions');
    localStorage.removeItem('loggedin');
    localStorage.removeItem('preferred_song_id');
    webAuth.logout({
      returnTo: process.env.appUrl,
      clientID: process.env.auth0.clientId,
    });
    window.history.replaceState(null, null, '/');
    // lock.logout();
    navigate('/');
  };

  const token = getCookie('accessToken');

  return (
    <div className="drawer-section">
      <MenuOutlined className="hamburger-btn" onClick={showDrawer} style={{ color: '#fff', fill: '#fff', zIndex: 100 }} />
      <Drawer className="drawer" onClose={onClose} open={open}>
        <div className="options">
          <div onClick={() => navigate('/')}>Home</div>
          {token && <div onClick={() => navigate('/voice-recording')}>Re-Record</div>}
          {/* <div onClick={() => navigate('/song-list')}>Select Song</div> */}
          {/* <div onClick={() => navigate('/play-list')}>Playlist</div> */}
          <div onClick={() => navigate('/logged-about')}>About</div>
          <div onClick={() => navigate('/faq')}>FAQ</div>
          {token && <div onClick={() => handleLogout()}>Log Out</div>}

        </div>
      </Drawer>
    </div>
  );
}

export default Hamburger;
