/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import {
  CaretRightFilled, HeartFilled, HeartOutlined, MenuOutlined, PauseCircleFilled, PlayCircleFilled,
  ShareAltOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Rcookie from 'react-cookies';
import { toast } from 'react-toastify';
import AudioPlayer from 'react-h5-audio-player';
import { useNavigate } from 'react-router-dom';
import { getProcessedSongListAction } from '../SongList/logic';
import Hamburger from '../Menu';
import { getCookie } from '../../common/utils';

function YourPlaylist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedSong, setSelectedSong] = useState(null);
  const [favouriteSong, setFavouriteSong] = useState([]);
  const audioRef = useRef(null);
  const [updateList, setUpdateList] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioBlobPlayer, setAudioBlobPlayer] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [currentIndex, setCurrentIndex] = useState();
  const [type, setType] = useState();
  const [playingSongId, setPlayingSongId] = useState();
  const [currentSong, setCurrentSong] = useState();
  const [loading, setLoading] = useState(false);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const data = useSelector((state) => state.getProcessedSongListData);
  const getProcessedSongListData = data?.data;
  console.log({ dataaaa: data });
  const userId = data?.user_id;
  const [isFavouriteSong, setIsFavouriteSong] = useState();

  useEffect(() => {
    if (!getCookie('accessToken')) {
      navigate('/', { replace: true });
    }
  }, []);

  useEffect(() => {
    dispatch(getProcessedSongListAction({}));
  }, [updateList]);

  useEffect(() => {
    setFavouriteSong([]);
    if (getProcessedSongListData?.length >= 0) {
      getProcessedSongListData?.map((song) => {
        if (song.is_favourite) {
          setFavouriteSong(((prev) => [...prev, song]));
        }
      });
    }
  }, [getProcessedSongListData]);

  useEffect(() => {
    const fetchAudioBlob = async () => {
      if (selectedSong) {
        try {
          const response = await fetch(selectedSong.audioSrc);
          const blob = await response.blob();
          setAudioBlob(blob);
        } catch (error) {
          console.error('Error fetching audio blob:', error);
        }
      }
    };

    fetchAudioBlob();

    return () => {
      if (audioBlob) {
        URL.revokeObjectURL(audioBlob);
      }
    };
  }, [selectedSong]);

  const handleSongClick = (song) => {
    setSelectedSong(song);
  };

  const handlePlay = () => {
    if (audioRef?.current) {
      audioRef?.current?.audio?.current?.play();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    if (audioRef?.current) {
      audioRef?.current?.audio?.current?.pause();
      setIsPlaying(false);
    }
  };

  const playAudio = (songData, showType, idx) => {
    handlePause();
    setCurrentIndex(idx);
    setCurrentSong(songData);
    setIsFavouriteSong(songData?.is_favourite);
    setLoading(true);
    setTimeout(() => {
      if (idx > -1 && idx < getProcessedSongListData.length) {
        setPlayingSongId(idx);
      }
    }, [2000]);
    const endPoint = showType === 'original' ? 'download_predefined_song' : 'download_inferenced_song';
    axios.get(`${process.env.apiUrl}v0/${endPoint}?song_id=${songData?.song_id}`, {
      headers: {
        Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`),
      },
      responseType: 'arraybuffer',
    })
      .then((response) => {
        setLoading(false);
        const blob = new Blob([response.data]);
        setAudioBlobPlayer(URL.createObjectURL(blob));
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const playPreviousSong = () => {
    handlePause();
    const prevSongIndex = playingSongId - 1;
    if (prevSongIndex >= 0 && prevSongIndex < getProcessedSongListData.length) {
      setPlayingSongId(prevSongIndex);
      playAudio(getProcessedSongListData[prevSongIndex], type, prevSongIndex);
    }
  };

  const playNextSong = () => {
    handlePause();
    const nextSongIndex = playingSongId + 1;
    if (nextSongIndex >= 0 && nextSongIndex < getProcessedSongListData.length) {
      setPlayingSongId(nextSongIndex);
      playAudio(getProcessedSongListData[nextSongIndex], type, nextSongIndex);
    }
  };

  const AddRemoveFavouriteSong = (songDetails) => {
    axios.post(`${process.env.apiUrl}v0/save_favourite_song?song_id=${songDetails?.song_id}&is_favourite=${!isFavouriteSong}`, songDetails?.song_id, {
      headers: {
        Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`),
      },
    })
      .then((response) => {
        setUpdateList(response);
        setIsFavouriteSong(!isFavouriteSong);
        toast.success(response?.data, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .catch((err) => {
        toast.error(err.data, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };
  return (
    <div className="play-list-page">
      <Row className="row row-common">
        <Col span={12} className="section-1">
          <div className="logos">
            <img className="lullabai-logo" src="/images/logo-ai.png" alt="Lullabai Logo" />
            <img className="alembic-icon" src="/images/alembic-icon.svg" alt="Alembic Icon" />
          </div>
        </Col>
        <Hamburger isOpen={false} className="hamberger-icon" />

        <Col span={12} className="section-2">

          {showAudioPlayer
            ? (
              <div className="section-2-play-song-wishlist">
                <div className="sound-icon disabled" style={{ height: '200px', width: '100%' }}>
                  {isPlaying && (
                    <div className="sound-wave" style={{ height: '150px', width: '90%' }}>
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                      <i className="bar" />
                    </div>
                  )}
                </div>
                <div className="title">
                  <div>
                    {currentSong?.title}
                    <div style={{ fontSize: '16px', color: '#8a8a8a' }}>{currentSong?.artist}</div>
                  </div>
                  {' '}
                  <div className="options" onClick={() => AddRemoveFavouriteSong(currentSong)}>
                    {/* <HeartFilled style={{ color: '#FF6000' }} className="play-icons" onClick={() => AddRemoveFavouriteSong(currentSong)} /> */}
                    {isFavouriteSong
                      ? <HeartFilled style={{ color: '#FF6000', cursor: 'pointer' }} className="play-icons" />
                      : <HeartOutlined style={{ color: '#FF6000', cursor: 'pointer' }} className="play-icons" />}

                  </div>
                </div>

                <div className="audio-player-wrapper">
                  <AudioPlayer
                    ref={audioRef}
                    autoPlay
                    src={audioBlobPlayer}
                    onPlay={handlePlay}
                    onPause={handlePause}
                  />
                </div>
                <div className="controls">
                  <ShareAltOutlined style={{ color: '#FF6000', visibility: 'hidden' }} className="play-icons" />
                  <StepBackwardOutlined className="play-icons" onClick={() => playPreviousSong()} />
                  {!isPlaying ? <PlayCircleFilled onClick={() => handlePlay()} style={{ color: '#ff6000' }} className="play-btn" />
                    : <PauseCircleFilled onClick={() => handlePause()} style={{ color: '#ff6000' }} className="play-btn" />}
                  <StepForwardOutlined className="play-icons" onClick={() => playNextSong()} />
                  <MenuOutlined className="play-icons-menu" style={{ visibility: 'hidden' }} />
                  {/* <HeartFilled style={{ color: '#FF6000' }} className="play-icons" /> */}
                </div>
              </div>
            )

            : (
              <div className="section-2-song-list">
                <div className="heading-2">Your Songs</div>
                <div className="player-list">
                  <div className="list">
                    {favouriteSong?.map((song, idx) => (

                      <div className="songs" key={idx} onClick={() => handleSongClick(song)}>
                        <div className="audio">
                          <div className="index-box">{idx + 1 > 9 ? idx + 1 : `0${idx + 1}`}</div>
                          <div>
                            {song.title.length > 25 ? (`${song.title.substring(0, 25)}...`) : song.title}
                            <br />
                            <span>{song?.artist}</span>
                          </div>
                        </div>
                        <div className="options">
                          {/* <HeartFilled style={{ color: '#FF6000', cursor: 'pointer' }} onClick={() => AddRemoveFavouriteSong(song)} /> */}

                          {/* {isFavouriteSong
                            ? <HeartFilled style={{ color: '#FF6000', cursor: 'pointer' }} />
                            : <HeartOutlined style={{ color: '#FF6000', cursor: 'pointer' }} />} */}
                          <CaretRightFilled
                            onClick={() => { setType('original'); playAudio(song, 'original', idx); }}
                            alt="Play Button"
                            style={{ color: '#FF6000' }}
                          />
                          {song?.inference_status === 'COMPLETED' ? (
                            <CaretRightFilled
                              className="play-button"
                              onClick={() => { setType('own'); playAudio(song, 'own', idx); }}
                              alt="Play Button"
                            />
                          ) : <img className="sandclock-logo" src="/images/sand-clock.png" alt="Lullabai Logo" />}
                        </div>
                      </div>

                    ))}
                    {favouriteSong.length === 0 && <div>No song in the list</div>}
                  </div>
                  {favouriteSong.length !== 0
                    && (
                      <div className="section-2-play-song-wishlist" style={{ width: '100%' }}>

                        <div className="audio-player-wrapper">
                          <AudioPlayer
                            ref={audioRef}
                            autoPlay
                            src={audioBlobPlayer}
                            onPlay={handlePlay}
                            onPause={handlePause}
                          />
                        </div>
                        {currentSong
                          && (
                            <>
                              <div className="now-playing">Now Playing</div>
                              <div className="wishlist-controls">
                                <div className="index-box" style={{ borderRadius: '10px' }}>{currentIndex + 1 > 9 ? currentIndex + 1 : `0${currentIndex + 1}`}</div>
                                <div className="song-title">
                                  {currentSong?.title.length > 25 ? (`${currentSong?.title.substring(0, 25)}...`) : currentSong?.title}
                                </div>
                                <div className="sound-icon disabled">
                                  {/* {isPlaying && ( */}
                                  <div className="sound-wave">
                                    <i className="bar" />
                                    <i className="bar" />
                                    <i className="bar" />
                                    <i className="bar" />
                                    <i className="bar" />
                                    <i className="bar" />
                                    <i className="bar" />
                                    <i className="bar" />
                                    <i className="bar" />
                                    <i className="bar" />
                                    <i className="bar" />
                                    <i className="bar" />
                                    <i className="bar" />
                                    <i className="bar" />
                                    <i className="bar" />
                                  </div>
                                  {/* )} */}
                                </div>
                                {/* {!isPlaying ? <PlayCircleFilled style={{ color: '#ff6000' }} onClick={() => handlePlay()} className="play-btn" />
                                  : <PauseCircleFilled style={{ color: '#ff6000' }} onClick={() => handlePause()} className="play-btn" />} */}
                                <PlayCircleFilled style={{ color: '#ff6000' }} onClick={() => { handlePlay(); setShowAudioPlayer(true); }} className="play-btn" />
                              </div>
                            </>
                          )}
                      </div>
                    )}
                </div>
              </div>
            )}
        </Col>
      </Row>
    </div>
  );
}

export default YourPlaylist;
