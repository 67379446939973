/* eslint-disable no-constant-condition */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import {
  Col, Modal, Row, Spin,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  TwitterShareButton, TwitterIcon, FacebookMessengerShareButton, FacebookMessengerIcon,
  LinkedinShareButton,
  InstapaperShareButton,
  WhatsappShareButton,
  FacebookShareButton,
} from 'react-share';
import {
  CaretRightFilled, FacebookOutlined, HeartFilled, HeartOutlined, InstagramFilled, LinkedinFilled, MenuOutlined, PauseCircleFilled, PlayCircleFilled, ShareAltOutlined, StepBackwardOutlined, StepForwardOutlined, TwitterOutlined, VerticalLeftOutlined, VerticalRightOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import Rcookie from 'react-cookies';
import AudioPlayer from 'react-h5-audio-player';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';
import songs from './demoAudio';
import { getProcessedSongListAction } from './logic';
import Hamburger from '../Menu';
import { getCookie } from '../../common/utils';
import Newheader from '../headerNew';
import { getRecordingListAction } from '../VoiceRecording/logic';

function SongList() {
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const urlSearchParams = new URLSearchParams(location.search);
  const playableSongData = useSelector((state) => state.playableSongData);
  const voiceRecordData = useSelector((state) => state?.getRecordingList);
  const [audioBlob, setAudioBlob] = useState(null); // State to store audio blob
  const [type, setType] = useState();
  const [playingSongId, setPlayingSongId] = useState();

  const [selectedSong, setSelectedSong] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audioBlobPlayer, setAudioBlobPlayer] = useState(null);
  const [audioElement, setAudioElement] = useState(null);
  const [showAudioPlayer, setShowAudioPlayer] = useState();
  const [currentSong, setCurrentSong] = useState();
  const [isFavouriteSong, setIsFavouriteSong] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [shareImageType, setShareImageType] = useState();
  const [loader, setLoader] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);

  const [showSong, setShowSong] = useState({ title: '', song: '' }); // Initialize showSong state
  const data = useSelector((state) => state.getProcessedSongListData);
  const getProcessedSongListData = data?.data?.data;
  const user_id = data?.data?.user_id;
  const song_id_from_url = urlSearchParams.get('song_id');
  const user_id_from_url = urlSearchParams.get('user_id');

  useEffect(() => {
    if (!song_id_from_url && !user_id_from_url && !getCookie('accessToken')) {
      navigate('/', { replace: true });
    }
    if (!song_id_from_url && !user_id_from_url) {
      dispatch(getProcessedSongListAction({}));
    }
  }, []); // Include dispatch in dependency array

  useEffect(() => {
    if (!song_id_from_url && !user_id_from_url) {
      // dispatch(getRecordingListAction({}));
    }
  }, []);
  useEffect(() => {
    const fetchAudioBlob = async () => {
      if (selectedSong) {
        try {
          const response = await fetch(selectedSong.audioSrc);
          const blob = await response.blob();
          setAudioBlob(blob);
        } catch (error) {
          console.error('Error fetching audio blob:', error);
        }
      }
    };

    fetchAudioBlob();

    return () => {
      if (audioBlob) {
        URL.revokeObjectURL(audioBlob);
      }
    };
  }, [selectedSong]);

  useEffect(() => {
    if (audioElement && selectedSong && audioBlob) {
      audioElement.pause();
      audioElement.load();
      audioElement.play();
    }
  }, [selectedSong, audioBlob, audioElement]);

  const handleSongClick = (song) => {
    setSelectedSong(song);
  };

  const reloadData = () => {
    if (!song_id_from_url && !user_id_from_url) {
      window.location.reload();
    }
  };

  useEffect(() => {
    // Retrieve or set endTime in localStorage
    let intervalId = null;
    const timer = voiceRecordData?.data?.created_at ? Number(voiceRecordData?.data?.created_at) + 6 * 60 * 1000 : 0;
    if (timer && !song_id_from_url && !user_id_from_url) {
      const endTime = timer;

      // Function to update time remaining
      const updateRemainingTime = () => {
        const currentTime = new Date().getTime();
        const remainingTime = endTime - currentTime;
        console.log({ remainingTime });
        if (remainingTime > 0) {
          setTimeRemaining(remainingTime);
        } else {
          setTimeRemaining(0);
        }
      };

      // Update time every second
      intervalId = setInterval(updateRemainingTime, 1000);

    // Cleanup function to clear the interval
    }
    return () => clearInterval(intervalId);
  }, [voiceRecordData]);

  const handlePlay = () => {
    if (audioRef.current && audioRef.current.audio.current) {
      const audioElementPlay = audioRef.current.audio.current;

      // Make sure the audio element is ready
      if (audioElementPlay.readyState >= 2) {
        audioElementPlay.play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.log('Playback failed:', error);
          });
      } else {
        // Listen for 'canplay' event and then play
        audioElementPlay.addEventListener('canplay', () => {
          audioElementPlay.play()
            .then(() => {
              setIsPlaying(true);
            })
            .catch((error) => {
              console.log('Playback failed:', error);
            });
        });
        audioElementPlay.load(); // Ensure the audio element is loaded
      }
    }
  };

  const handlePause = () => {
    if (audioRef.current) {
      audioRef.current.audio.current.pause();
      setIsPlaying(false);
    }
  };
  const playAudio = (songData, showType, idx) => {
    // Pause any current audio and reset states
    handlePause();
    setCurrentSong(songData);
    setIsFavouriteSong(songData?.is_favourite);
    setShowAudioPlayer(true);
    setLoader(true);

    const params = new URLSearchParams({ name: 'value' });

    // Use setTimeout if needed for certain UI effects
    setTimeout(() => {
      if (idx > -1 && idx < getProcessedSongListData.length) {
        setPlayingSongId(idx);
      }
    }, 2000);

    // Define the endpoint based on `showType`
    const endPoint = showType === 'original' ? 'download_predefined_song' : 'download_inferenced_song';

    // Construct the request URL
    const requestParams = showType !== 'original' && song_id_from_url && user_id_from_url
      ? `${process.env.apiUrl}v0/${endPoint}?song_id=${songData?.song_id}&user_id=${user_id_from_url}`
      : `${process.env.apiUrl}v0/${endPoint}?song_id=${songData?.song_id}`;

    // Perform the Axios request to download the audio
    axios.get(requestParams, {
      headers: {
        Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`),
      },
      responseType: 'arraybuffer', // Expected audio data
    })
      .then((response) => {
        // Convert the response into a Blob
        const blob = new Blob([response.data], { type: 'audio/mpeg' }); // Ensure you define a valid type for audio

        // Create a URL for the audio Blob
        const audioURL = URL.createObjectURL(blob);

        // Set the audio Blob URL to your player
        setAudioBlobPlayer(audioURL);
        setLoader(false);

        // Optionally, automatically play after loading (check Safari compatibility)
        const audioElementPlay = document.getElementById('audio-player'); // Replace with your actual audio element reference
        if (audioElementPlay) {
          audioElementPlay.src = audioURL;
          audioElementPlay.load();

          // Handle the play promise to avoid issues on Safari
          audioElementPlay.play()
            .then(() => {
              console.log('Audio playing');
              setIsPlaying(true);
            })
            .catch((error) => {
              console.error('Audio playback failed:', error);
            });
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error('Error downloading audio:', err);
      });
  };

  // const playPreviousSong = (data) => {
  //   playAudio(getProcessedSongListData[playingSongId - 1], type);
  //   console.log(getProcessedSongListData[playingSongId - 1]);
  // };
  // const playNextSong = (data) => {
  //   playAudio(getProcessedSongListData[playingSongId + 1], type);
  //   console.log(getProcessedSongListData[playingSongId + 1]);
  // };

  useEffect(() => {
    if (song_id_from_url && user_id_from_url) {
      const playAudioId = {
        song_id: song_id_from_url,
        user_id: user_id_from_url,
      };
      setType(false);
      playAudio(playAudioId, 'notOriginal');
    }
  }, [song_id_from_url, user_id_from_url]);

  const playPreviousSong = () => {
    handlePause();
    const prevSongIndex = playingSongId - 1;
    if (prevSongIndex >= 0 && prevSongIndex < getProcessedSongListData.length) {
      setPlayingSongId(prevSongIndex);
      playAudio(getProcessedSongListData[prevSongIndex], type, prevSongIndex);
    }
  };

  const playNextSong = () => {
    handlePause();
    const nextSongIndex = playingSongId + 1;
    if (nextSongIndex >= 0 && nextSongIndex < getProcessedSongListData.length) {
      setPlayingSongId(nextSongIndex);
      playAudio(getProcessedSongListData[nextSongIndex], type, nextSongIndex);
    }
  };

  // useEffect(() => {
  //   const song_id = urlSearchParams.get('song_id');
  //   type = urlSearchParams.get('type');
  //   const endPoint = type === 'original' ? 'download_predefined_song' : 'download_inferenced_song';
  //   const params = ({
  //     song_id,
  //   });
  //   axios.get(`${process.env.apiUrl}v0/${endPoint}?song_id=${song_id})}`, {
  //     headers: {
  //       // Authorization: getCookie('accessToken'),
  //       Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`),
  //     },
  //     responseType: 'arraybuffer',
  //   })
  //     .then((response) => {
  //       const blob = new Blob([response.data]);
  //       console.log(blob);
  //       console.log(URL.createObjectURL(blob));
  //       setAudioBlob(URL.createObjectURL(blob));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       // dispatch(downloadDocumentFailure(err));
  //     });
  // }, []);

  const AddRemoveFavouriteSong = () => {
    axios.post(`${process.env.apiUrl}v0/save_favourite_song?song_id=${currentSong?.song_id}&is_favourite=${!isFavouriteSong}`, currentSong?.song_id, {
      headers: {
        Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`),
      },
    })
      .then((response) => {
        setIsFavouriteSong(!isFavouriteSong);
        toast.success(response?.data, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .catch((err) => {
        toast.error(err.data, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  const pushToUrl = (song) => {
    const song_id = song?.song_id;
    const params = new URLSearchParams();
    setSearchParams({ song_id, user_id });
  };
  const shareUrl = `https://iamvivekananda.echoesofenlightenment.org/song-list?song_id=${song_id_from_url}&user_id=${user_id_from_url}`;
  const title = 'I am Vivekanad- Echoes of enlightenment';
  const description = "Listen to Swami Vivekananda's historic speech in my voice, generated by uisng Partex AI!";
  const images = {
    facebook: 'https://innoplexus-logo.s3.eu-central-1.amazonaws.com/iamvivekananda-echoesofenlightenment-org/facebook.png',
    twitter: 'https://innoplexus-logo.s3.eu-central-1.amazonaws.com/iamvivekananda-echoesofenlightenment-org/twitter.png',
    instagram: 'https://innoplexus-logo.s3.eu-central-1.amazonaws.com/iamvivekananda-echoesofenlightenment-org/instagram.png',
    linkedin: 'https://innoplexus-logo.s3.eu-central-1.amazonaws.com/iamvivekananda-echoesofenlightenment-org/linkedin.png',
  };

  const titleForShare = "Listen to Swami Vivekananda's historic speech in my voice, generated by uisng Partex AI";

  const onClickShare = (key) => {
    setShareImageType(key);
  };

  const remainingMin = Math.floor(timeRemaining / (1000 * 60));
  const remainingSec = Math.floor((timeRemaining % (1000 * 60)) / 1000);
  const paddedMinutes = String(remainingMin).padStart(2, '0');
  const paddedSeconds = String(remainingSec).padStart(2, '0');

  return (
    (getProcessedSongListData || (user_id_from_url && song_id_from_url)) && (
      <div className="landing-page">
        <Helmet>
          <meta property="og:url" content={shareUrl} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={images?.[shareImageType]} />
          <meta name="title" content={title} />
          <meta name="description" content={description} />
          <meta name="image" content={images?.[shareImageType]} />
        </Helmet>
        <Modal className="modal-hover" open={isModalOpen} onCancel={() => setIsModalOpen(false)} style={{ top: '200px' }}>
          <div>
            <label className="song-name">{showSong.title}</label>
          </div>
          <div className="controls">
            <img src="/images/share_icon.svg" />
            <img src="/images/left_arrow.svg" />
            <PlayCircleFilled onClick={() => navigate(`/play-song?song_id=${showSong.audioSrc}&title=${showSong.title}`)} className="play-btn" />
            <img src="/images/right_arrow.svg" />
            {/* <span onClick={AddRemoveFavouriteSong}>
              {isFavouriteSong
                ? <HeartFilled style={{ fontSize: '48px', color: '#FF6000' }} />
                : <HeartOutlined style={{ fontSize: '48px', color: '#FF6000' }} />}
            </span> */}
          </div>
        </Modal>
        <Newheader />
        <div className="song-list-page new-landing-page">
          <Row className="row row-common">
            <Col span={10} className="section-1">
              <div className="logos">
                <img className="lullabai-logo" src="/images/logo-ai.png" alt="Vivekanand Logo" />
                <img className="alembic-icon" src="/images/alembic-icon.svg" alt="Alembic Icon" />
              </div>

            </Col>
            <Hamburger isOpen={false} className="hamberger-icon" />

            {!showAudioPlayer ? (
              <Col span={10} className="section-2">
                <div className="section-2-song-list">
                  <div className="heading">Play your speech</div>
                  <div className="list">
                    {getProcessedSongListData?.length >= 0 && getProcessedSongListData?.map((song, idx) => (
                      <div
                        onClick={() => {
                          if (song?.inference_status === 'COMPLETED') {
                            setType('own');
                            pushToUrl(song);
                            playAudio(song, 'own', idx);
                          }
                        }}
                        className="songs"
                        style={{
                          cursor: 'pointer',
                        }}
                        key={idx}
                      >
                        <div className="audio" /* onMouseOver={() => { setIsModalOpen(true); setShowSong({ title: song.file_name, audioSrc: song.song_id }); }} */>
                          {song?.title || `song ${idx + 1}`}
                          <br />
                          {/* <div className="artist-name">{song?.artist}</div>  */}
                        </div>
                        <div className="playing-icons">
                          {' '}
                          {song?.inference_status === 'COMPLETED' ? (
                            <PlayCircleFilled
                              style={{
                                fontSize: '40px',
                              }}
                              className="play-button"
                            // onClick={() => {
                            //   setType('own');
                            //   pushToUrl(song);
                            //   playAudio(song, 'own', idx);
                            // }}
                            // onClick={() => { navigate('/playing-song?song_id=${}'); }}
                              alt="Play Button"
                            />
                          ) : (
                            <>
                              {timeRemaining <= 0
                                ? <div className="reload-btn" role="presentation" title="Reload" onClick={reloadData}>Reload</div>
                                : (
                                  <p>
                                    {paddedMinutes}
                                    :
                                    {paddedSeconds}
                                  </p>
                                )}
                              {/* <img
                                className="sandclock-logo"
                                src="/images/sand-clock.png"
                                alt=""
                              /> */}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {getProcessedSongListData?.[0]?.inference_status !== 'COMPLETED' && timeRemaining <= 0 && <div className="speech-info">It&apos;s taking longer than expected. Please reload in a few minutes. </div>}
                  <div className="powerdby-sec">
                    <div className="powerdby-img" />
                  </div>
                  {/* {audioBlob && (
                <audio controls ref={(element) => setAudioElement(element)}>
                  <source src={URL.createObjectURL(audioBlob)} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              )} */}
                </div>
              </Col>
            ) : (
              <Col span={10} className="section-2">
                <div className="section-2-song-list">
                  <div
                    className="share-your-speech"
                  >
                    <div className="share-your-speech-text">
                      Share your speech on:
                    </div>
                    <div className="share-your-speech-social-section">
                      <div className="share-your-speech-social-section-btn">
                        <TwitterShareButton
                          style={{
                            fontSize: '40px',
                          }}
                          beforeOnClick={() => onClickShare('twitter')}
                          title={titleForShare}
                          url={shareUrl}
                          hashtags={['IamVivekananda', '11sep', 'EchoesOfEnlightenment']}
                        >
                          <TwitterOutlined className="sb-color" />
                        </TwitterShareButton>
                      </div>
                      <div className="share-your-speech-social-section-btn">
                        <FacebookShareButton
                          style={{
                            fontSize: '40px',
                          }}
                          beforeOnClick={() => onClickShare('facebook')}
                          title={titleForShare}
                          url={shareUrl}
                          hashtags="#IamVivekananda #11sep #EchoesOfEnlightenment"
                        >
                          <FacebookOutlined className="sb-color" />
                        </FacebookShareButton>
                      </div>
                      <div className="share-your-speech-social-section-btn">
                        <LinkedinShareButton
                          style={{
                            fontSize: '40px',
                          }}
                          beforeOnClick={() => onClickShare('linkedin')}
                          title={titleForShare}
                          url={shareUrl}
                        >
                          <LinkedinFilled className="sb-color" />
                        </LinkedinShareButton>
                      </div>

                      <div className="share-your-speech-social-section-btn">
                        <WhatsappShareButton
                          style={{
                            fontSize: '40px',
                          }}
                          beforeOnClick={() => onClickShare('facebook')}
                          title={titleForShare}
                          url={shareUrl}
                        >
                          <WhatsAppOutlined className="sb-color" />
                        </WhatsappShareButton>
                      </div>
                    </div>
                  </div>
                  {type ? (
                    <>
                      <div className="heading">
                        Now playing speech in your voice
                      </div>
                      <div className={`section-2-play-song-wishlist section-2-play-song-${type}`}>
                        <div className="close-icon-div">
                          <div className="close-icon" onClick={() => { setShowAudioPlayer(false); }}>x</div>
                        </div>
                        <div className="title">
                          {/* {urlSearchParams.get('title')} */}
                          {' '}
                          {/* Song Title */}
                          {currentSong?.title}
                        </div>
                        <div className="controls">
                          {/* <ShareAltOutlined className="play-icons" style={{ visibility: 'hidden' }} /> */}
                          <VerticalRightOutlined className="play-icons" onClick={() => playPreviousSong()} />
                          {/* <StepBackwardOutlined className="play-icons" /> */}
                          {!isPlaying ? <PlayCircleFilled onClick={() => handlePlay()} className="play-btn" />
                            : <PauseCircleFilled onClick={() => handlePause()} className="play-btn" />}
                          {/* <StepForwardOutlined className="play-icons" /> */}
                          <VerticalLeftOutlined className="play-icons" onClick={() => playNextSong()} />
                          {/* <span onClick={AddRemoveFavouriteSong}>
                            {isFavouriteSong
                              ? <HeartFilled className="play-icons" />
                              : <HeartOutlined className="play-icons" />}
                          </span> */}
                        </div>
                        <div className="audio-player-wrapper">
                          <Spin />
                          <AudioPlayer
                            ref={audioRef}
                            autoPlay={false}
                            src={audioBlobPlayer}
                            onPlay={handlePlay}
                            onPause={handlePause}
                          />
                        </div>
                        {/* <div className="social-share-links">
                          <TwitterShareButton title="Share On Twitter" url={shareUrl} ><TwitterOutlined /></TwitterShareButton>
                          <div style={{
                            marginLeft: '30px',
                          }}
                          >
                            <FacebookMessengerShareButton title="Share On Twitter" url={shareUrl}><FacebookOutlined /></FacebookMessengerShareButton>
                          </div>
                        </div> */}
                      </div>
                    </>
                  ) : (
                    <div className="section-2-play-song-wishlist">
                      <div className="heading">
                        Now playing speech in your voice
                      </div>
                      <div className="sound-icon disabled">
                        {isPlaying && (
                          <div className="sound-wave">
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                            <i className="bar" />
                          </div>
                        )}
                      </div>
                      <div
                        className="title"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <div>
                          {/* {urlSearchParams.get('title')} */}
                          {' '}
                          {/* Song Title */}
                          {/* {currentSong?.title} */}
                        </div>
                        {' '}
                        <div className="options">
                          {/* <img className="lullabai-logo" src="/images/logo-ai.png" alt="Swami Vivekanand Logo" /> */}
                          {/* <span onClick={AddRemoveFavouriteSong}>
                            {isFavouriteSong
                              ? <HeartFilled style={{ color: '#FF6000' }} className="play-icons" />
                              : <HeartOutlined style={{ color: '#FF6000' }} className="play-icons" />}
                          </span> */}
                        </div>
                      </div>
                      <div className="audio-player-wrapper">
                        <Spin spinning={loader}>
                          <AudioPlayer
                            ref={audioRef}
                            autoPlay={false}
                            src={audioBlobPlayer}
                            onPlay={handlePlay}
                            onPause={handlePause}
                          />
                        </Spin>
                      </div>
                      <div
                        className="controls"
                      >
                        <ShareAltOutlined style={{ color: '#FF6000', visibility: 'hidden' }} className="play-icons" />
                        <StepBackwardOutlined className="play-icons" />
                        {!isPlaying ? <PlayCircleFilled onClick={() => handlePlay()} className="play-btn" />
                          : <PauseCircleFilled onClick={() => handlePause()} className="play-btn" />}
                        <StepForwardOutlined className="play-icons" />
                        <MenuOutlined className="play-icons-menu" style={{ visibility: 'hidden' }} />
                        {/* <HeartFilled style={{ color: '#FF6000' }} className="play-icons" /> */}
                      </div>
                    </div>
                  )}
                  <div className="powerdby-sec">
                    <div className="powerdby-img" />
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>
    )
  );
}

export default SongList;
