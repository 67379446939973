/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import AudioPlayer from 'react-h5-audio-player';
import Rcookie from 'react-cookies';
import {
  HeartFilled, MenuOutlined, PauseCircleFilled, PlayCircleFilled, ShareAltOutlined, StepBackwardOutlined, StepForwardOutlined, VerticalLeftOutlined, VerticalRightOutlined,
} from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { useDispatch, useSelector } from 'react-redux';
import axios, { Axios } from 'axios';
import WaveForm from '../waveform/WaveForm';
import Hamburger from '../Menu';
import { getPlayableSongAction } from './logic';
import { getCookie } from '../../common/utils';

function PlaySong() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(true);
  const audioRef = useRef(null);
  const urlSearchParams = new URLSearchParams(location.search);
  const playableSongData = useSelector((state) => state.playableSongData);
  const [audioBlob, setAudioBlob] = useState(null); // State to store audio blob

  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.audio.current.play();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    if (audioRef.current) {
      audioRef.current.audio.current.pause();
      setIsPlaying(false);
    }
  };
  let audioUrl;
  let type = urlSearchParams.get('type');

  useEffect(() => {
    if (!getCookie('accessToken')) {
      navigate('/', { replace: true });
    }

    const song_id = urlSearchParams.get('song_id');
    type = urlSearchParams.get('type');
    const endPoint = type === 'original' ? 'download_predefined_song' : 'download_inferenced_song';
    const params = ({
      song_id,
    });
    axios.get(`${process.env.apiUrl}v0/${endPoint}?song_id=${song_id.toString()}`, {
      headers: {
        // Authorization: getCookie('accessToken'),
        Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`),
      },
      responseType: 'arraybuffer',
    })
      .then((response) => {
        const blob = new Blob([response.data]);

        setAudioBlob(URL.createObjectURL(blob));
      })
      .catch((err) => {
        // dispatch(downloadDocumentFailure(err));
      });
  }, []);

  return (
    <div className="song-play-page">
      <Row className="row row-common">
        <Col span={12} className="section-1">
          <div className="logos">
            <img className="lullabai-logo" src="/images/logo-ai.png" alt="Vivekanand Logo" />
            <img className="alembic-icon" src="/images/alembic-icon.svg" alt="Alembic Icon" />
          </div>
        </Col>
        <Hamburger isOpen={false} className="hamberger-icon" />

        <Col span={12} className="section-2">
          <Hamburger isOpen={false} className="hamberger-icon" />
          {type ? (
            <div className={`section-2-play-song-${type}`}>
              <div className="title">
                {/* {urlSearchParams.get('title')} */}
                {' '}
                Song Title
              </div>
              <div className="controls">
                <ShareAltOutlined className="play-icons" style={{ visibility: 'hidden' }} />
                <VerticalRightOutlined className="play-icons" />
                {/* <StepBackwardOutlined className="play-icons" /> */}
                {!isPlaying ? <PlayCircleFilled onClick={() => handlePlay()} className="play-btn" />
                  : <PauseCircleFilled onClick={() => handlePause()} className="play-btn" />}
                {/* <StepForwardOutlined className="play-icons" /> */}
                <VerticalLeftOutlined className="play-icons" />
                {/* <HeartFilled className="play-icons" /> */}
              </div>
              <div className="audio-player-wrapper">
                <AudioPlayer
                  ref={audioRef}
                  autoPlay={false}
                  src={audioBlob}
                  onPlay={handlePlay}
                  onPause={handlePause}
                />
              </div>

            </div>
          ) : (
            <div className="section-2-play-song-wishlist">
              <div className="sound-icon disabled">
                {isPlaying && (
                  <div className="sound-wave">
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                    <i className="bar" />
                  </div>
                )}
              </div>
              <div className="title">
                <div>
                  {/* {urlSearchParams.get('title')} */}
                  {' '}
                  Song Title
                </div>
                {' '}
                <div className="options">
                  {/* <img class Name="lullabai-logo" src="/images/repeat.svg" alt="Vivekanand Logo" /> */}
                  {/* <HeartFilled style={{ color: '#FF6000' }} className="play-icons" /> */}
                </div>
              </div>

              <div className="audio-player-wrapper">
                <AudioPlayer
                  ref={audioRef}
                  autoPlay={false}
                  src={audioBlob}
                  onPlay={handlePlay}
                  onPause={handlePause}
                />
              </div>
              <div className="controls">
                <ShareAltOutlined style={{ color: '#FF6000', visibility: 'hidden' }} className="play-icons" />
                <StepBackwardOutlined className="play-icons" />
                {!isPlaying ? <PlayCircleFilled onClick={() => handlePlay()} className="play-btn" />
                  : <PauseCircleFilled onClick={() => handlePause()} className="play-btn" />}
                <StepForwardOutlined className="play-icons" />
                <MenuOutlined className="play-icons-menu" style={{ visibility: 'hidden' }} />
                {/* <HeartFilled style={{ color: '#FF6000' }} className="play-icons" /> */}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default PlaySong;
