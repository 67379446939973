/* eslint-disable no-debugger */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-trailing-spaces */

import { faL } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Auth0Lock from 'auth0-lock';
import auth0 from 'auth0-js';
import { useDispatch, useSelector } from 'react-redux';
import Rcookie from 'react-cookies';
import { toast } from 'react-toastify';
import {
  ArrowLeftOutlined,
  // EyeInvisibleOutlined,
  // EyeOutlined,
  EyeFilled,
  EyeInvisibleFilled,
} from '@ant-design/icons';
import { getCookie, removeCookies, setCookie } from '../../common/utils';
import { getRecordingListAction } from '../VoiceRecording/logic';
import { removeSession } from '../../app/Status/logic';
import Newheader from '../headerNew';

function LandingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [showModalContents, setShowModalContents] = useState(false);
  const [showSignLoginModal, setShowSignLoginModal] = useState(false);
  const [showAbout, setShowAbout] = useState(false);

  const [showLanding, setShowLanding] = useState(true);
  const [isLoggedIn, setLoggedIn] = useState(false);

  const [authModal, setAuthModal] = useState(false);
  const voiceRecordData = useSelector((state) => state?.getRecordingList);
  const [showLoginPage, setShowLoginPage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [instructionId, setInstructionId] = useState(null);
  // const [showSignUpQuestionModal, setShowSignUpQuestionModal] = useState(false);
  // const [showSignUpPasswordModal, setShowSignUpPasswordModal] = useState(false);
  // const [showDatePicker, setShowDatePicker] = useState(false);

  const [loginLoading, setLoginLoading] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [fPLoading, setFPLoading] = useState(false);

  const webAuth = new auth0.WebAuth({
    domain: process.env.auth0.domain,
    clientID: process.env.auth0.clientId,
    audience: process.env.auth0.audience,
    response_type: 'token id_token',
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) && dispatch(getRecordingListAction({}));

    // setTimeout(() => {

    //   }
    // }, 333000);

    // setTimeout(() => {
    //   setShowAbout(false);

    //   if (!getCookie('accessToken') && !localStorage.getItem('loggedin')) {
    //     removeSession();
    //     removeCookies();
    //     localStorage.removeItem('loggedin');
    //     setShowSignLoginModal(true);
    //   }
    // }, 115000);
  }, [!isLoggedIn]);

  useEffect(() => {
    if (voiceRecordData?.data?.voice_recorded !== undefined) {
      if (voiceRecordData?.data?.voice_recorded) {
        // navigate('/song-list');
        // dispatch(getProcessedSongListAction({}));
      } else {
        // navigate('/about');
      }
    }
  }, [voiceRecordData?.data?.voice_recorded]);

  const skipToNext = () => {
    setShowAbout(false);
    if (!getCookie('accessToken') && !localStorage.getItem('loggedin')) {
      removeSession();
      removeCookies();
      localStorage.removeItem('loggedin');
      setShowSignLoginModal(true);
    } else {
      navigate('/loggedin');
    }
  };

  // useEffect(() => {
  //   dispatch(getRecordingListAction({}));

  //   // if (getCookie('accessToken')) {
  //   //   voiceRecordData?.data?.voice_recorded === true ? navigate('/song-list') : navigate('/about');
  //   // } else {
  //   setTimeout(() => {
  //     setShowLanding(false);
  //     setShowModalContents(true);
  //     setShowAbout(true);
  //   }, 1500);

  //   setTimeout(() => {
  //     setShowAbout(false);
  //     console.log(voiceRecordData);
  //     if (!getCookie('accessToken')) {
  //       setShowSignLoginModal(true);

  //       // console.log(voiceRecordData?.data?.voice_recorded);
  //       // voiceRecordData?.data?.voice_recorded === true ? navigate('/song-list') : navigate('/about');
  //     }
  //   }, 8000);
  //   // }
  // }, []);

  const handleSubmit = () => {
    navigate('/voice-recording');
  };

  const location = useLocation();

  const lock = new Auth0Lock(
    process.env.auth0.clientId,
    process.env.auth0.domain,
    {
      container: '',
      autoclose: true,
      theme: {
        logo: 'https://iamvivekananda.echoesofenlightenment.org/59af006c3b55248d91be.png',
      },
    },
  );

  const gotoNextStep = () => {
    setShowLanding(false);
    setShowModalContents(true);
    setShowAbout(true);
    if (((getCookie('accessToken') && !localStorage.getItem('loggedin')) || (!getCookie('accessToken') && localStorage.getItem('loggedin')))) {
      localStorage.removeItem('loggedin');
      removeSession();
      removeCookies();
      webAuth.logout({
        returnTo: process.env.appUrl,
        clientID: process.env.auth0.clientId,
      });
    }
  };

  const getToken = async (token) => {
    try {
      const response = await axios.get(`${process.env.apiUrl}v0/callback`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const bearer = `${response.data.accessToken}`;
        setCookie(JSON.stringify(response.data.permissions), 'permissions');
        setCookie(bearer, 'accessToken');
        setLoggedIn(true);
        localStorage.setItem('loggedin', true);
        lock.hide();
        setTimeout(() => {
          setLoginLoading(false);
        }, 1500);
        dispatch(getRecordingListAction({}));

        // Set loading to true while waiting for the response
        // setLoading(true);
        navigate('/loggedin');
      }
    } catch (err) {
      setTimeout(() => {
        setLoginLoading(false);
      }, 1500);
      localStorage.clear();
      removeSession();
      removeCookies();
      webAuth.logout({
        returnTo: process.env.appUrl,
        clientID: process.env.auth0.clientId,
      });
    }
  };

  // useEffect(() => {
  //   if (voiceRecordData?.data?.voice_recorded) {
  //     if (voiceRecordData?.data?.voice_recorded === true) {
  //       navigate('/song-list');
  //       dispatch(getProcessedSongListAction({}));
  //     } else {
  //       navigate('/about');
  //     }
  //   }
  // }, [voiceRecordData?.data?.voice_recorded, loading]);

  // Set loading state initially to true

  // useEffect(() => {
  //   if (authModal) {
  //     lock.show();
  //   }
  // }, [authModal]);

  // useEffect(() => {
  //   if (location.hash.includes('access_token')) {
  //     lock.on('authenticated', (authResult) => {
  //       // setLockInitialized(true);
  //       lock.getUserInfo(authResult.accessToken, (error) => {
  //         if (error) {
  //           setCookie(false, 'loggedIn');
  //           return;
  //         }
  //         if (!authResult) {
  //           return;
  //         }
  //         if (authResult) {
  //           lock.hide();
  //           getToken(authResult.accessToken);
  //         }
  //       });
  //     });
  //   }
  // }, [location.hash.includes('access_token')]);

  const handleLogin = async (emailInput, passwordInput) => {
    try {
      setLoginLoading(true);
      const body = {
        email: emailInput,
        password: passwordInput,
      };
      const response = await axios.post(
        `${process.env.apiUrl}v0/appLogin`,
        body,
      );
      if (response.status === 200 && response.data.access_token) {
        const { access_token } = response.data;
        getToken(`${access_token}`);
        toast.success('Login Successful', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoginLoading(false);
      } else {
        toast.error(response?.data?.error ?? 'Login Error please try again', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoginLoading(false);
      }
    } catch (err) {
      toast.error(err?.response?.data?.error, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setLoginLoading(false);
    }
  };

  const handleForgotPassword = async (emailInput) => {
    try {
      setFPLoading(true);
      const body = {
        email: emailInput,
      };
      const response = await axios.post(
        `${process.env.apiUrl}v0/forgot_password`,
        body,
      );
      if (response.status === 200) {
        toast.success('If you are a registered user, you will receive instructions to reset your password in your email inbox.', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setFPLoading(false);
        setIsForgotPassword(false);
      } else {
        toast.error(response?.data?.error ?? 'Something went wrong, please try again', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setFPLoading(false);
        setIsForgotPassword(false);
      }
    } catch (err) {
      toast.error(err?.response?.data?.error ?? 'Something went wrong, please try again', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setFPLoading(false);
      setIsForgotPassword(false);
    }
  };

  const handleEmail = (e) => {
    e.preventDefault();
    if (e.target[0]?.value && e.target[1]?.value && !isForgotPassword) {
      handleLogin(e.target[0].value, e?.target[1].value);
    } else if (isForgotPassword && e.target[0].value) {
      handleForgotPassword(e.target[0].value);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassClick = () => {
    setIsForgotPassword(true);
  };

  // return (
  //   <>
  //     {!showModalContents && (
  //       <div className="landing-page-firstview">
  //        <Row className="row row-common">
  //           <Col span={24} className="section-1">
  //             <div className="logos">
  //               <img className="lullabai-logo" src="/images/logo-ai.png" />
  //               <img className="alembic-icon" src="/images/alembic-icon.svg" />
  //             </div>
  //           </Col>
  //         </Row>
  //       </div>
  //     )}
  //     {!!showModalContents && (
  //       <div className={showModalContents ? 'new-landing-page' : 'new-landing-page welcome-page'}>
  //        <Row className="row row-common">
  //           <Col span={12} className={showAbout ? 'section-1 divider-none' : 'section-1  divider'}>
  //             <div className="logos">
  //               {/* <img className="lullabai-logo" src="/images/logo-ai.png" />
  //               <img className="alembic-icon" src="/images/alembic-icon.svg" /> */}
  //             </div>
  //           </Col>
  //           <Col span={12} className="section-2">
  //             {!!showModalContents && !!showAbout && (
  //               <div className="about-section">
  //                 <div className="about">
  //                   <img className="lullabai-logo" src="/images/logo-ai.png" />
  //                   {/* <div className="title">
  //                     <span className="title-first">About</span>
  //                     <span className="title-last">LullabAI</span>
  //                   </div> */}
  //                   <div className="desc">
  //                     Welcome to LullbaAl, a web-based platform that is designed for mothers with speech impairment who yearn to sing their baby a lullaby.
  //                     <br />
  //                     <br />

  //                     Powered with Al, it uses the texture of the voice and the essence of the mother’s warmth to recreate melodious lullabies from a pre-recorded list of songs.
  //                   </div>
  //                 </div>
  //                 <div className="next-btn-wrap">
  //                   {/* <Button
  //                     className="next"
  //                     onClick={() => {
  //                       setShowAbout(false);
  //                       setShowSignLoginModal(true);
  //                       if (getCookie('accessToken')) {
  //                         voiceRecordData?.data?.voice_recorded ? (navigate('voice-recording')) : (navigate('song-list'));
  //                       }
  //                     }}
  //                   >
  //                     Next
  //                   </Button> */}
  //                   <img className="alembic-icon" src="/images/alembic-icon.svg" />
  //                 </div>
  //               </div>
  //             )}
  //             {!!showModalContents && !!showSignLoginModal && (
  //               <div className="login-table">
  //                 <div className="heading"/* onClick={() => { navigate('/signup');}} */>Sign up for free</div>
  //                 <div className="login-table-content">
  //                   {/* <div className="login-table-options">Continue with phone number</div> */}
  //                   <div className="login-table-options" onClick={() => navigate('/signup')}>Continue with Email ID</div>
  //                   {/* <div className="login-table-options">Continue with Facebook</div> */}
  //                   <div className="login-table-options-borderless" onClick={() => setAuthModal(true)}>
  //                     Already a user? &nbsp;
  //                     <span style={{ color: '#ff6000' }}>Login</span>
  //                   </div>
  //                   {/* <div className="login-table-options-borderless" onClick={() => { setShowSignLoginModal(false); setShowLogin(true); }}>Login</div> */}
  //                 </div>
  //               </div>
  //             )}
  //             {/* {!!showModalContents && !!showSignUpQuestionModal && (
  //               <div className="login-table">
  //                 <div>Create account</div>
  //                 <div className="question">What’s your email address?</div>
  //                 <input />
  //                 <Button className="next" onClick={() => { setShowSignUpQuestionModal(false); setShowSignUpPasswordModal(true); }}>Next</Button>
  //               </div>
  //             )}
  //             {!!showModalContents && !!showSignUpPasswordModal && (
  //               <div className="login-table">
  //                 <div>Create account</div>
  //                 <div className="pass">Create your password</div>
  //                 <div className="pass-note"> Use at least 8 characters.</div>
  //                 <input type="password" />
  //                 <Button className="next" onClick={() => { setShowSignUpPasswordModal(false); setShowDatePicker(true); }}>Next</Button>
  //               </div>
  //             )}
  //             {!!showModalContents && !!showDatePicker && (
  //               <div className="login-table">
  //                 <div className="pass">What is your date of birth</div>
  //                 <DatePicker className="birthdate-picker" format={['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY']} />
  //                 <Button className="next" onClick={() => navigate('/voice-recording')}>Next</Button>
  //               </div>
  //             )} */}
  //             {/* {!!showLogin && (
  //               <div className="login-table">
  //                 <form onSubmit={handleSubmit} className="form">
  //                   <input placeholder="Name" />
  //                   <input placeholder="Password" type="password" />
  //                   <Button className="login-btn" type="submit" onClick={() => navigate('/voice-recording')}>Login</Button>
  //                 </form>
  //               </div>
  //             )} */}
  //           </Col>
  //         </Row>
  //         {/* <div className="mobile-footer-logo">
  //           <img className="alembic-icon" src="/images/alembic-icon.svg" />
  //         </div> */}
  //       </div>
  //     )}
  //   </>
  // );

  return (
    showLoginPage ? (
      <div className="landing-page">
        <Newheader />
        <div className="login-page-proper new-landing-page">
          <Row className="row row-common">
            <Col span={10} className="section-1">
              <div className="logos">
                <img className="lullabai-logo" src="/images/logo-ai.png" />
                <img className="alembic-icon" src="/images/alembic-icon.svg" />
              </div>
            </Col>
            <Col span={10} className="section-2">
              <div className="heading-text">{isForgotPassword ? 'Reset Password' : 'Login here'}</div>
              <form
                className="login-table-form-wrap"
                onSubmit={handleEmail}
                autoComplete="off"
              >
                <div className="login-table">
                  <div className="password-sec-combine">
                    <div className="note">Email ID</div>
                    <input type="email" required autoFocus />
                    {!isForgotPassword && <div className="note">Password</div>}
                    {!isForgotPassword && (
                      <div className="password-input-container">
                        <input
                          type={showPassword ? 'email' : 'password'}
                          required
                          style={{ paddingRight: '45px' }}
                        />
                        <button
                          type="button"
                          className="toggle-password"
                          onClick={togglePasswordVisibility}
                          aria-label={
                            showPassword ? 'Hide password' : 'Show password'
                          }
                          aria-pressed={showPassword}
                        >
                          {showPassword ? (
                            <EyeFilled />
                          ) : (
                            <EyeInvisibleFilled />
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                  {!isForgotPassword && <div className="login-forgot-password" onClick={handleForgotPassClick}>Forgot Password?</div>}
                  {!isForgotPassword && (
                    <div className="login-buttons">
                      <button className="next" type="submit" disabled={loginLoading}>
                        Login
                      </button>
                      <button
                        className="back"
                        type="button"
                        onClick={() => {
                          setShowLoginPage(false);
                        }}
                      >
                        Back
                      </button>
                    </div>
                  )}

                  {isForgotPassword && (
                    <div className="login-buttons">
                      <button className="next" type="submit" disabled={fPLoading}>
                        Submit
                      </button>
                      <button
                        className="back"
                        type="button"
                        onClick={() => {
                          setIsForgotPassword(false);
                        }}
                        disabled={fPLoading}
                      >
                        Back
                      </button>
                    </div>
                  )}
                </div>
              </form>
              <div className="powerdby-sec">
                <div className="powerdby-img" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    ) : (
      <div className="landing-page container-common">
        <Newheader />
        {!showModalContents && (
          <div className="landing-page-firstview new-landing-page">
            <Row className="row row-common">
              <Col span={10} className="section-1">
                <div className="logos">
                  <img className="lullabai-logo" src="/images/logo-ai.png" />
                  <img className="alembic-icon" src="/images/alembic-icon.svg" />
                </div>
              </Col>
              <Col span={10} className="section-2 heading-name">
                <div className="logos">
                  <div className="title-main">
                    I am Vivekanand
                    <span
                      role="presentation"
                      className="blink_me"
                      onClick={() => {
                        gotoNextStep();
                      }}
                    />
                  </div>
                  <div className="title-main-sub">Experience the power of Swami Vivekanand&apos;s iconic speech—delivered in your own voice.</div>
                </div>
              </Col>
            </Row>
            <div className="powerdby-sec">
              <div className="powerdby-img" />
            </div>
          </div>
        )}
        {!!showModalContents && (
          <div
            className={`new-landing-page ${showSignLoginModal ? 'show-signup-background' : 'new-landing-page welcome-page'}`}
          >
            <Row className="row row-common">
              <Col
                span={10}
                className={
                  showAbout ? 'section-1 divider-none' : 'section-1 divider'
                }
              >
                <div className="logos">
                  <img className="lullabai-logo" src="/images/logo-ai.png" />
                  <img
                    className="alembic-icon"
                    src="/images/alembic-icon.svg"
                  />
                </div>
              </Col>
              <Col
                span={10}
                className={`section-2 ${showSignLoginModal ? 'center-content' : ''}`}
              >
                {!!showModalContents && !!showAbout && (
                  <div className="about-section">
                    <div className="about">
                      {/* <img className="lullabai-logo" src="/images/logo-ai.png" /> */}
                      <div className="desc">
                        I am Vivekanand is a unique web-based platform that harnesses the advanced capabilities of Partex AI to bring history to life.
                        <br />
                        <br />
                        By capturing the unique texture and tone of your voice, this platform recreates Swami Vivekanand&apos;s legendary speech, making it sound as if it were spoken by you.
                        <br />
                        <br />
                        Step into the past, connect with the wisdom of Swami Vivekanand, and let your voice echo the timeless words of enlightenment.
                      </div>
                      <div className="skip-btn-sec">
                        <Button onClick={() => skipToNext()}>Next</Button>
                      </div>
                    </div>
                    <div className="next-btn-wrap">
                      <img
                        className="alembic-icon"
                        src="/images/alembic-icon.svg"
                      />
                    </div>
                    <div className="powerdby-sec">
                      <div className="powerdby-img" />
                    </div>
                  </div>
                )}
                {!!showModalContents && !!showSignLoginModal && (
                  <div className="login-table">
                    <div className="heading" onClick={() => navigate('/signup')}>
                      Sign up for free
                    </div>
                    <div className="login-table-content">
                      <div
                        className="login-table-options"
                        onClick={() => navigate('/signup')}
                      >
                        Click to create
                      </div>
                      <div
                        className="login-table-options-borderless"
                      >
                        Already a user? &nbsp;
                        <span style={{ color: '#ff6000' }} onClick={() => setShowLoginPage(true)}>Login</span>
                      </div>
                    </div>
                    <div className="powerdby-sec">
                      <div className="powerdby-img" />
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>
    )
  );
}

export default LandingPage;
